<template>
	<div class="range-slider">
		<input
			type="range"
			v-model.number="selectedStartValue"
			:min="min"
			:max="max"
			:step="step"
			@input="updateValues"
		/>
		<input
			type="range"
			v-model.number="selectedEndValue"
			:min="min"
			:max="max"
			:step="step"
			@input="updateValues"
		/>
		<div class="range-slider__selected" :style="selectedCssVars"></div>
	</div>
</template>

<script>
export default {
	name: 'RangeSlider',
	emits: ['updated'],
	props: {
		min: {
			type: Number,
			default: 0
		},
		max: {
			type: Number,
			default: 100
		},
		step: {
			type: Number,
			default: 1
		},
		startValue: {
			type: Number,
			default: 0
		},
		endValue: {
			type: Number,
			default: 100
		}
	},
	data() {
		return {
			selectedStartValue: null,
			selectedEndValue: null
		}
	},
	computed: {
		selectedCssVars() {
			return {
				width: (Math.abs(this.selectedEndValue - this.selectedStartValue) / this.max) * 100 + '%',
				left: (Math.min(this.selectedStartValue, this.selectedEndValue) / this.max) * 100 + '%'
			}
		}
	},
	watch: {
		startValue(value) {
			this.selectedStartValue = value
		},
		endValue(value) {
			this.selectedEndValue = value
		}
	},
	mounted() {
		this.selectedStartValue = this.startValue
		this.selectedEndValue = this.endValue
	},
	methods: {
		updateValues() {
			const startValue = Math.min(this.selectedStartValue, this.selectedEndValue)
			const endValue = Math.max(this.selectedStartValue, this.selectedEndValue)
			this.$emit('updated', { start: startValue, end: endValue })
		}
	}
}
</script>

<style lang="less">
.range-slider {
	position: relative;
	height: 6px;
	background-color: var(--identity-color-lightgrey);
	border-radius: 999px;
	margin: 20px 0px;
	.range-slider__selected {
		position: absolute;
		height: 100%;
		background-color: var(--identity-color-primary);
		width: 50%;
		border-radius: 999px;
		pointer-events: none;
	}
}

input[type='range'] {
	margin: 0px;
	top: 50%;
	position: absolute;
	-webkit-appearance: none;
	width: 100%;

	&:focus {
		outline: none;
	}

	&::-webkit-slider-runnable-track {
		.track();
	}

	&::-webkit-slider-thumb {
		.thumb();
		-webkit-appearance: none;
		border: 0px;
	}

	&::-moz-range-track {
		.track();
	}
	&::-moz-range-thumb {
		.thumb();
	}
}

.track() {
	background: none;
	width: 100%;
	height: 0px;
}

.thumb() {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background: var(--identity-color-light);
	cursor: pointer;
	transform: translate(0, -50%);
	z-index: 3;
	position: relative;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
</style>
