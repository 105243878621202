<template v-balance-text>
	<div class="geolocation-banner banner" v-if="showBanner">
		<div class="banner__header">
			<h3>{{ $t('message.front_voluntary_geo_title') }}</h3>
			<div @click="close" class="banner__close">
				<Icon type="close" />
			</div>
		</div>
		<div class="caption">{{ $t('message.front_geo_disclaimer') }}</div>
		<BasicButton
			buttonStyle="primary"
			:text="$t('message.front_voluntary_geo_button')"
			@click="askGeolocationPermission"
		/>
	</div>
</template>

<script>
import Icon from '@/components/bits/Icon'
import BasicButton from '@/components/bits/BasicButton'

export default {
	name: 'GeolocationBanner',
	components: {
		Icon,
		BasicButton
	},
	data() {
		return {
			loadingGeolocation: false,
			showBanner: false
		}
	},
	emits: ['granted', 'declined', 'close'],
	mounted() {
		this.checkGeolocationPermission()
	},
	methods: {
		checkGeolocationPermission() {
			//currently not available on Safari and iOS Safari
			if (navigator.permissions) {
				navigator.permissions.query({ name: 'geolocation' }).then((result) => {
					if (['granted', 'denied'].includes(result.state)) return this.askGeolocationPermission()
					this.showBanner = true
				})
			} else {
				this.showBanner = true
			}

			this.loaded = true
		},
		askGeolocationPermission() {
			if (navigator.geolocation) {
				setTimeout(() => {
					this.showBanner = false
				}, 500)
				const geoOptions = {
					timeout: 20_000
				}
				navigator.geolocation.getCurrentPosition(this.grantedGeolocation, this.declinedGeolocation, geoOptions)
			} else {
				this.$emit('declined')
			}
		},
		grantedGeolocation(position) {
			this.$emit('granted', position)
		},
		declinedGeolocation() {
			//this.loadingGeolocation = false
			this.$emit('declined')
		},
		close() {
			this.$emit('close')
		}
	}
}
</script>
