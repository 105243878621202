<template>
	<div class="card">
		<h2>{{ $t('message.front_humidity_logs_title') }}</h2>
		<HumiditySenseChart
			:primaryColor="parameters.color.primary"
			:secondaryColor="parameters.color.secondary"
			:fontColor="parameters.color.darkgrey"
			:gridColor="parameters.color.lightgrey"
			:dataPoints="senseData.dataPoints"
			:dataLabels="dataLabels"
		/>
	</div>
	<div class="card">
		<h2>{{ $t('message.front_humidity_download_data_title') }}</h2>
		{{ $t('message.front_humidity_download_data_information_text') }}

		<BasicButton
			@click="downloadData"
			:text="$t('message.front_humidity_download_data_button')"
			buttonStyle="primary"
		/>
		<div class="caption">{{ $t('message.front_humidity_download_data_format_text') }}</div>
	</div>
</template>

<script>
import HumiditySenseChart from '@/components/charts/HumiditySenseChart'
import BasicButton from '@/components/bits/BasicButton'

export default {
	name: 'humidity-sense',
	components: {
		HumiditySenseChart,
		BasicButton
	},
	data() {
		return {
			datacollection: null
		}
	},
	computed: {
		locale() {
			return this.$i18n.locale
		},
		senseData() {
			return this.$store.state.senseData
		},
		temparatureRange() {
			return this.$store.state.temparatureRange
		},
		parameters() {
			return this.$store.state.parameters
		},
		dataPoints() {
			return this.senseData.dataPoints
		},
		dataLabels() {
			return this.$store.state.senseDataTimestamps.map((timestamp) => {
				const date = new Date(timestamp * 1000)
				const options = {
					hour12: false,
					day: 'numeric',
					month: 'short',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit'
				}
				return new Intl.DateTimeFormat(this.locale, options).format(date)
			})
		},
		latestDataLabels() {
			//only use the last labels corresponding to limited datapoints
			return this.dataLabels.slice(-this.dataPointCount)
		},
		dataPointCount() {
			return Math.min(this.dataPoints.temperature.length, this.dataPoints.humidity.length)
		}
	},
	mounted() {},
	methods: {
		downloadData() {
			let csv = 'Date,Humidity,Celcius,Fahrenheit\n'
			this.dataPoints.humidity.forEach((dataPoint, index) => {
				const humidity = dataPoint
				const dateTime = this.latestDataLabels[index]
				const celcius = this.dataPoints.temperature[index]
				const fahrenheit = Math.round(((celcius * 9) / 5 + 32) * 10) / 10

				csv += `"${dateTime}",${humidity}%,${celcius},${fahrenheit}`
				csv += '\n'
			})

			let hiddenElement = document.createElement('a')
			hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
			hiddenElement.target = '_blank'
			hiddenElement.download = 'sense_data_' + this.senseData.id + '.csv'
			hiddenElement.click()
		}
	}
}
</script>
