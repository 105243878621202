<template>
	<svg
		width="40px"
		height="40px"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 40 40"
		style="enable-background: new 0 0 40 40"
		xml:space="preserve"
		preserveAspectRatio="xMidYMid"
	>
		<circle :fill="fill" cx="20" cy="20" r="20" />
		<path
			:fill="iconFill"
			d="M26.9,16.69c-0.17-0.34-0.52-0.55-0.9-0.55h-4.15l3.24-6.17c0.16-0.31,0.15-0.68-0.03-0.98c-0.18-0.3-0.51-0.48-0.86-0.48
	H18.1c-0.42,0-0.8,0.27-0.94,0.66l-4.1,11.46c-0.11,0.31-0.06,0.65,0.12,0.91c0.19,0.27,0.49,0.42,0.82,0.42h2.72l-1.81,8.33
	c-0.1,0.46,0.13,0.93,0.56,1.12c0.13,0.06,0.28,0.09,0.42,0.09c0.31,0,0.6-0.14,0.8-0.4L26.8,17.74
	C27.03,17.44,27.06,17.03,26.9,16.69z M17.83,26.28l1.11-5.11c0.06-0.3-0.01-0.6-0.2-0.84c-0.19-0.24-0.48-0.37-0.78-0.37h-2.55
	l3.38-9.46h3.75l-3.24,6.17c-0.16,0.31-0.15,0.68,0.03,0.98c0.18,0.3,0.51,0.48,0.86,0.48h3.79L17.83,26.28z"
		/>
	</svg>
</template>

<script>
export default {
	data() {
		return {
			fill: 'grey',
			iconFill: 'black'
		}
	}
}
</script>
