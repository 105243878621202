<template>
	<div class="app-store-buttons">
		<a href="https://play.google.com/store/apps/details?id=online.tapp.tapp&hl=nl&gl=US" target="_blank"><PlayStoreBadge /></a>
		<a href="https://apps.apple.com/us/app/tapp-paper-dataloggers/id6446580389" target="_blank"><AppStoreBadge /></a>
	</div>
</template>

<script>
import PlayStoreBadge from '@/assets/playStoreBadge'
import AppStoreBadge from '@/assets/appStoreBadge'

export default {
	components: {
		PlayStoreBadge,
		AppStoreBadge
	}
}
</script>
