<template>
	<div class="card">
		<h2>{{ $t('message.front_temperature_current_title') }}</h2>
		<div class="card__columns">
			<CircleMeter
				class="card__column"
				:stroke="parameters.color.lightgrey"
				:activeStroke="parameters.color.primary"
				:rangeMin="temparatureRange.min"
				:rangeMax="temparatureRange.max"
				:currentValue="senseData.currentTemp"
			/>
			<div class="card__column --vertical-centered">{{ $t('message.front_temperature_current_text') }}</div>
		</div>
	</div>
	<div class="card">
		<h2>{{ $t('message.front_temperature_logs_title') }}</h2>
		<TempSenseChart
			:lineColor="parameters.color.primary"
			:fontColor="parameters.color.darkgrey"
			:gridColor="parameters.color.lightgrey"
			:dataPoints="senseData.dataPoints"
			:dataLabels="dataLabels"
		/>
		<div class="spacer"></div>
		<h2>Get more data</h2>
		To get access to all the datapoints on this label, download our app.
		<AppStoreButtons />
	</div>
	<div class="card">
		<h2>{{ $t('message.front_temperature_download_data_title') }}</h2>
		{{ $t('message.front_temperature_download_data_information_text') }}

		<BasicButton
			@click="downloadData"
			:text="$t('message.front_temperature_download_data_button')"
			buttonStyle="primary"
		/>
		<div class="caption">{{ $t('message.front_temperature_download_data_format_text') }}</div>
	</div>
</template>

<script>
import TempSenseChart from '@/components/charts/TempSenseChart'
import CircleMeter from '@/components/charts/CircleMeter'
import BasicButton from '@/components/bits/BasicButton'
import AppStoreButtons from '@/components/bits/AppStoreButtons'

export default {
	name: 'temp-sense',
	components: {
		TempSenseChart,
		CircleMeter,
		BasicButton,
		AppStoreButtons
	},
	data() {
		return {
			datacollection: null
		}
	},
	computed: {
		locale() {
			return this.$i18n.locale
		},
		senseData() {
			return this.$store.state.senseData
		},
		temparatureRange() {
			return this.$store.state.temparatureRange
		},
		parameters() {
			return this.$store.state.parameters
		},
		dataPoints() {
			return this.senseData.dataPoints
		},
		dataLabels() {
			return this.$store.state.senseDataTimestamps.map((timestamp) => {
				const date = new Date(timestamp * 1000)
				const options = {
					hour12: false,
					day: 'numeric',
					month: 'short',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit'
				}
				return new Intl.DateTimeFormat(this.locale, options).format(date)
			})
		},
		latestDataLabels() {
			//only use the last labels corresponding to limited datapoints
			return this.dataLabels.slice(-this.dataPoints.length)
		}
	},
	mounted() {},
	methods: {
		downloadData() {
			let csv = 'Date,Celcius,Fahrenheit\n'
			this.senseData.dataPoints.forEach((dataPoint, index) => {
				const dateTime = this.latestDataLabels[index]
				const celcius = dataPoint
				const fahrenheit = Math.round(((dataPoint * 9) / 5 + 32) * 10) / 10

				csv += `"${dateTime}",${celcius},${fahrenheit}`
				csv += '\n'
			})

			let hiddenElement = document.createElement('a')
			hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
			hiddenElement.target = '_blank'
			hiddenElement.download = 'sense_data_' + this.senseData.id + '.csv'
			hiddenElement.click()
		}
	}
}
</script>
