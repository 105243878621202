<template>
	<div class="card">
		<h2>{{ $t('message.front_shock_logs_title') }}</h2>
		<ShockSenseChart
			:primaryColor="parameters.color.primary"
			:secondaryColor="parameters.color.secondary"
			:darkColor="parameters.color.dark"
			:fontColor="parameters.color.darkgrey"
			:gridColor="parameters.color.lightgrey"
			:dataPoints="senseData.dataPoints"
			:dataLabels="senseDataLabels"
		/>
	</div>
	<!--<div class="card">
		<h2>{{ $t('message.front_shock_download_data_title') }}</h2>
		{{ $t('message.front_shock_download_data_information_text') }}

		<Button @click="downloadData" :text="$t('message.front_shock_download_data_button')" buttonStyle="primary" />
		<div class="caption">{{$t('message.front_shock_download_data_format_text')}}</div>

	</div>-->
</template>

<script>
import ShockSenseChart from '@/components/charts/ShockSenseChart'
//import Button from '@/components/bits/Button'

export default {
	name: 'shock-sense',
	components: {
		ShockSenseChart
		//Button
	},
	data() {
		return {
			datacollection: null
		}
	},
	computed: {
		locale() {
			return this.$i18n.locale
		},
		senseData() {
			return this.$store.state.senseData
		},
		parameters() {
			return this.$store.state.parameters
		},
		senseDataLabels() {
			return this.$store.state.senseDataTimestamps.map((timestamp) => {
				const date = new Date(timestamp * 1000)
				const options = {
					hour12: false,
					day: 'numeric',
					month: 'short',
					//year: '2-digit',
					hour: '2-digit',
					minute: '2-digit'
				}
				return new Intl.DateTimeFormat(this.locale, options).format(date)
			})
		}
	},
	mounted() {},
	methods: {
		downloadData() {
			const vm = this

			const dates = this.$store.state.senseDataTimestamps.map((timestamp) => {
				const date = new Date(timestamp * 1000)
				const options = {
					hour12: false,
					day: 'numeric',
					month: 'long',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit'
				}
				return new Intl.DateTimeFormat(this.locale, options).format(date)
			})

			let csv = 'Date,Celcius,Fahrenheit\n'
			this.senseData.dataPoints.forEach(function (dataPoint, index) {
				let celcius = dataPoint
				let fahrenheit = Math.round(((dataPoint * 9) / 5 + 32) * 10) / 10

				csv += '"' + dates[index] + '",' + celcius + ',' + fahrenheit
				csv += '\n'
			})

			let hiddenElement = document.createElement('a')
			hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
			hiddenElement.target = '_blank'
			hiddenElement.download = 'sense_data_' + this.senseData.id + '.csv'
			hiddenElement.click()
		}
	}
}
</script>
