<template>
	<canvas ref="chart" width="400" height="250"></canvas>
	<template v-if="showRange && dataPoints.length">
		<p>{{ $t('message.front_temperature_logs_range') }}</p>
		<Range
			:max="dataPoints.length"
			:startValue="dataPointsShown.start"
			:endValue="dataPointsShown.end"
			@updated="updateRange"
		/>
	</template>
</template>

<script>
import Chart from 'chart.js/auto'
import Range from '@/components/bits/Range'
let chart

export default {
	name: 'LineChart',
	components: {
		Range
	},
	data() {
		return {
			dataPointsShown: {
				start: this.showRange ? Math.max(this.dataPoints.length - 30, 0) : 0,
				end: this.dataPoints.length
			}
		}
	},
	props: {
		fontColor: {
			type: String,
			default: 'grey'
		},
		gridColor: {
			type: String,
			default: 'grey'
		},
		lineColor: {
			type: String,
			default: 'black'
		},
		dataPoints: {
			default: () => [120, 19, 3, 5, 2, 3]
		},
		dataLabels: {
			default: () => ['1', '2', '3', '4', '5', '6']
		},
		showRange: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		selectedDataPoints() {
			if (!this.dataPoints.length) return [0]
			return this.dataPoints.slice(this.dataPointsShown.start, this.dataPointsShown.end)
		},
		latestDataLabels() {
			//only use the last labels corresponding to limited datapoints
			return this.dataLabels.slice(-this.dataPoints.length)
		},
		selectedDataLabels() {
			return this.latestDataLabels.slice(this.dataPointsShown.start, this.dataPointsShown.end)
		}
	},
	watch: {
		selectedDataLabels(newLabels) {
			chart.data.labels = newLabels
			chart.update()
		},
		selectedDataPoints(newData) {
			chart.data.datasets[0].data = newData
			chart.update()
		}
	},
	mounted() {
		Chart.defaults.font.family = 'Jakarta Sans'
		Chart.defaults.color = this.fontColor

		const ctx = this.$refs.chart
		chart = new Chart(ctx, {
			type: 'line',
			data: {
				labels: this.selectedDataLabels,
				datasets: [
					{
						data: this.selectedDataPoints,
						borderWidth: 3,
						borderColor: this.lineColor,
						pointBackgroundColor: this.lineColor,
						pointBorderColor: this.lineColor,
						backgroundColor: this.lineColor,
						fill: false,
						pointRadius: 3,
						hitRadius: 12,
						tension: 0.4
					}
				]
			},
			options: {
				responsive: true,
				maintainAspectRatio: this.maintainAspectRatio,
				animation: {
					duration: 0
				},
				scales: {
					x: {
						ticks: {
							beginAtZero: false,
							padding: 10
						},
						grid: {
							display: true,
							offset: false,
							drawBorder: false,
							drawOnChartArea: true,
							drawTicks: false,
							color: this.gridColor
						}
					},
					y: {
						ticks: {
							beginAtZero: false,
							padding: 10,
							callback: function (value, index, values) {
								return `${value}°C`
							},
							display: true,
							precision: 1,
							stepSize: 0.2
						},
						grid: {
							display: true,
							drawBorder: false,
							drawOnChartArea: true,
							drawTicks: false,
							color: this.gridColor
						}
					}
				},
				onHover: function (e) {
					const points = this.getElementsAtEventForMode(e, 'index', { axis: 'x', intersect: true }, false)
					if (points.length) e.native.target.style.cursor = 'pointer'
					else e.native.target.style.cursor = 'default'
				},
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						backgroundColor: this.colorLightTertiary,
						//boxPadding: 5,
						displayColors: false,
						titleColor: this.colorDark,
						titleFont: {
							size: 14
						},
						titleFontStyle: 'bold',
						titleMarginBottom: 2,
						bodyColor: this.colorDark,
						bodyFont: {
							size: 14
						},
						bodySpacing: 0,
						footerSpacing: 0,
						footerMarginTop: 0,
						padding: {
							top: 8,
							left: 15,
							bottom: 6,
							right: 15
						},
						cornerRadius: 10,
						caretSize: 7,
						callbacks: {
							label: function (context) {
								return `${context.parsed.y}°C`
							}
						}
					}
				}
			}
		})
	},
	methods: {
		updateRange(range) {
			this.dataPointsShown.start = range.start
			this.dataPointsShown.end = range.end
		}
	}
}
</script>
