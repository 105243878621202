<template>
	<div id="logo"><img :src="parameters.logo.url" /></div>
	<component v-bind:is="senseBlock"></component>

	<div class="card" v-if="parameters.uiElements?.callToAction">
		<h2>{{ parameters.uiElements?.callToAction?.title }}</h2>
		{{ parameters.uiElements?.callToAction?.text }}
		<BasicButton
			v-for="(button, index) in parameters.uiElements?.callToAction?.buttons"
			:key="index"
			:text="button.text"
			target="_blank"
			:href="button.href"
			:buttonStyle="button.style"
		/>
	</div>

	<transition name="slide-down">
		<GeolocationBanner
			v-show="showGeolocationBanner"
			@close="closeGeolocationBanner"
			@declined="closeGeolocationBanner"
			@granted="geolocationGranted"
		/>
	</transition>
</template>

<script>
import ShockSense from '@/components/blocks/ShockSense'
import HumiditySense from '@/components/blocks/HumiditySense'
import TempSense from '@/components/blocks/TempSense'

import BasicButton from '@/components/bits/BasicButton'
import GeolocationBanner from '@/components/banners/GeolocationBanner'
import { createTap, updateTap } from '@/compositions/useApiActions'

export default {
	name: 'SenseData',
	components: {
		ShockSense,
		HumiditySense,
		TempSense,
		BasicButton,
		GeolocationBanner
	},
	data() {
		return {
			datacollection: null,
			showGeolocationBanner: false
		}
	},
	computed: {
		locale() {
			return this.$i18n.locale
		},
		parameters() {
			return this.$store.state.parameters
		},
		senseBlock() {
			const type = this.$store.state.senseData.type
			const blockTypes = {
				shockSense: 'ShockSense',
				humiditySense: 'HumiditySense',
				tempSense: 'TempSense'
			}
			return blockTypes[type]
		}
	},
	watch: {
		'parameters.geolocation.active': {
			handler(isActive) {
				this.showGeolocationBanner = isActive
			},
			immediate: true
		}
	},
	methods: {
		closeGeolocationBanner() {
			this.showGeolocationBanner = false
		},
		geolocationGranted(geolocation) {
			const coordinates = {
				lat: geolocation.coords.latitude,
				lon: geolocation.coords.longitude,
				accuracy: geolocation.coords.accuracy
			}
			this.showGeolocationBanner = false
			this.$store.commit('setState', { key: 'coordinates', value: coordinates })
		}
	}
}
</script>
