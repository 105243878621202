<template>
	<div class="app-button">
		<svg
			version="1.1"
			id="artwork"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 119.7 40"
			style="enable-background: new 0 0 119.7 40"
			xml:space="preserve"
		>
			<g>
				<g>
					<g id="_Group_">
						<g id="_Group_2">
							<g id="_Group_3">
								<path
									id="_Path_"
									class="st0"
									d="M24.8,20.3c0-1.7,0.9-3.3,2.4-4.2c-0.9-1.3-2.4-2.1-4-2.2c-1.7-0.2-3.3,1-4.2,1  	c-0.9,0-2.2-1-3.6-1c-1.9,0.1-3.6,1.1-4.5,2.7c-1.9,3.3-0.5,8.3,1.4,11c0.9,1.3,2,2.8,3.4,2.8c1.4-0.1,1.9-0.9,3.6-0.9  	c1.7,0,2.1,0.9,3.6,0.9c1.5,0,2.4-1.3,3.3-2.7c0.7-0.9,1.2-2,1.5-3.1C25.9,24,24.8,22.2,24.8,20.3z"
								/>
								<path
									id="_Path_2"
									class="st0"
									d="M22,12.2c0.8-1,1.2-2.2,1.1-3.5c-1.2,0.1-2.4,0.7-3.2,1.7c-0.8,0.9-1.2,2.1-1.1,3.4  	C20.1,13.8,21.2,13.2,22,12.2z"
								/>
							</g>
						</g>
						<g>
							<path
								class="st0"
								d="M42.3,27.1h-4.7l-1.1,3.4h-2l4.5-12.4H41l4.5,12.4h-2L42.3,27.1z M38.1,25.6h3.8L40,20.1h-0.1L38.1,25.6z"
							/>
							<path
								class="st0"
								d="M55.2,26c0,2.8-1.5,4.6-3.8,4.6c-1.2,0.1-2.3-0.6-2.8-1.6h0v4.5h-1.9v-12h1.8v1.5h0c0.6-1,1.7-1.6,2.9-1.6  C53.6,21.3,55.2,23.2,55.2,26z M53.2,26c0-1.8-0.9-3-2.4-3c-1.4,0-2.4,1.2-2.4,3c0,1.8,1,3,2.4,3C52.3,29,53.2,27.8,53.2,26z"
							/>
							<path
								class="st0"
								d="M65.1,26c0,2.8-1.5,4.6-3.8,4.6c-1.2,0.1-2.3-0.6-2.8-1.6h0v4.5h-1.9v-12h1.8v1.5h0c0.6-1,1.7-1.6,2.9-1.6  C63.6,21.3,65.1,23.2,65.1,26z M63.2,26c0-1.8-0.9-3-2.4-3c-1.4,0-2.4,1.2-2.4,3c0,1.8,1,3,2.4,3C62.3,29,63.2,27.8,63.2,26  L63.2,26z"
							/>
							<path
								class="st0"
								d="M71.7,27c0.1,1.2,1.3,2,3,2c1.6,0,2.7-0.8,2.7-1.9c0-1-0.7-1.5-2.3-1.9l-1.6-0.4c-2.3-0.6-3.3-1.6-3.3-3.3  c0-2.1,1.9-3.6,4.5-3.6c2.6,0,4.4,1.5,4.5,3.6h-1.9c-0.1-1.2-1.1-2-2.6-2c-1.5,0-2.5,0.8-2.5,1.9c0,0.9,0.7,1.4,2.3,1.8l1.4,0.3  c2.5,0.6,3.6,1.6,3.6,3.4c0,2.3-1.9,3.8-4.8,3.8c-2.8,0-4.6-1.4-4.7-3.7L71.7,27z"
							/>
							<path
								class="st0"
								d="M83.3,19.3v2.1h1.7v1.5h-1.7v5c0,0.8,0.3,1.1,1.1,1.1c0.2,0,0.4,0,0.6,0v1.5c-0.3,0.1-0.7,0.1-1,0.1  c-1.8,0-2.5-0.7-2.5-2.4v-5.2h-1.3v-1.5h1.3v-2.1H83.3z"
							/>
							<path
								class="st0"
								d="M86.1,26c0-2.8,1.7-4.6,4.3-4.6c2.6,0,4.3,1.8,4.3,4.6c0,2.9-1.7,4.6-4.3,4.6C87.7,30.6,86.1,28.8,86.1,26z   M92.8,26c0-2-0.9-3.1-2.4-3.1S88,24,88,26c0,2,0.9,3.1,2.4,3.1S92.8,27.9,92.8,26L92.8,26z"
							/>
							<path
								class="st0"
								d="M96.2,21.4H98V23h0c0.2-1,1.2-1.7,2.2-1.6c0.2,0,0.4,0,0.6,0.1v1.7c-0.3-0.1-0.6-0.1-0.8-0.1  c-1,0-1.9,0.8-1.9,1.8c0,0.1,0,0.2,0,0.3v5.4h-1.9L96.2,21.4z"
							/>
							<path
								class="st0"
								d="M109.4,27.8c-0.2,1.6-1.9,2.8-3.9,2.8c-2.6,0-4.3-1.8-4.3-4.6c0-2.8,1.6-4.7,4.2-4.7c2.5,0,4.1,1.7,4.1,4.5  v0.6h-6.4v0.1c-0.1,1.3,0.8,2.4,2.1,2.6c0.1,0,0.2,0,0.3,0c0.9,0.1,1.8-0.4,2.1-1.3L109.4,27.8z M103.1,25.1h4.5  c0.1-1.2-0.9-2.2-2.1-2.3c-0.1,0-0.1,0-0.2,0C104.1,22.8,103.1,23.8,103.1,25.1C103.1,25.1,103.1,25.1,103.1,25.1z"
							/>
						</g>
					</g>
				</g>
				<g id="_Group_4">
					<g>
						<path
							class="st0"
							d="M37.8,8.7c1.5-0.1,2.7,1,2.8,2.4c0,0.2,0,0.3,0,0.5c0,1.9-1,3-2.8,3h-2.2v-6H37.8z M36.6,13.9h1.1  c1,0.1,1.9-0.7,2-1.8c0-0.1,0-0.3,0-0.4c0.1-1-0.6-2-1.6-2.1c-0.1,0-0.2,0-0.4,0h-1.1V13.9z"
						/>
						<path
							class="st0"
							d="M41.7,12.4c-0.1-1.2,0.7-2.2,1.9-2.3c1.2-0.1,2.2,0.7,2.3,1.9c0,0.1,0,0.3,0,0.4c0.1,1.2-0.7,2.2-1.9,2.3  c-1.2,0.1-2.2-0.7-2.3-1.9C41.7,12.7,41.7,12.6,41.7,12.4z M45,12.4c0-1-0.4-1.5-1.2-1.5c-0.8,0-1.2,0.6-1.2,1.5  c0,1,0.4,1.6,1.2,1.6C44.6,14,45,13.4,45,12.4L45,12.4z"
						/>
						<path
							class="st0"
							d="M51.6,14.7h-0.9l-0.9-3.3h-0.1l-0.9,3.3h-0.9l-1.2-4.5h0.9l0.8,3.4h0.1l0.9-3.4h0.9l0.9,3.4h0.1l0.8-3.4h0.9  L51.6,14.7z"
						/>
						<path
							class="st0"
							d="M53.9,10.2h0.9v0.7h0.1c0.2-0.5,0.8-0.8,1.3-0.8c0.8-0.1,1.5,0.5,1.6,1.4c0,0.1,0,0.2,0,0.3v2.9h-0.9V12  c0-0.7-0.3-1.1-1-1.1c-0.6,0-1.1,0.4-1.1,1c0,0.1,0,0.1,0,0.2v2.6h-0.9L53.9,10.2z"
						/>
						<path class="st0" d="M59.1,8.4H60v6.3h-0.9V8.4z" />
						<path
							class="st0"
							d="M61.2,12.4c-0.1-1.2,0.7-2.2,1.9-2.3c1.2-0.1,2.2,0.7,2.3,1.9c0,0.1,0,0.3,0,0.4c0.1,1.2-0.7,2.2-1.9,2.3  c-1.2,0.1-2.2-0.7-2.3-1.9C61.2,12.7,61.2,12.6,61.2,12.4z M64.6,12.4c0-1-0.4-1.5-1.2-1.5c-0.8,0-1.2,0.6-1.2,1.5  c0,1,0.4,1.6,1.2,1.6C64.1,14,64.6,13.4,64.6,12.4z"
						/>
						<path
							class="st0"
							d="M66.4,13.4c0-0.8,0.6-1.3,1.7-1.3l1.2-0.1v-0.4c0-0.5-0.3-0.7-0.9-0.7c-0.5,0-0.8,0.2-0.9,0.5h-0.9  c0.1-0.8,0.8-1.3,1.8-1.3c1.1,0,1.8,0.6,1.8,1.5v3.1h-0.9v-0.6h-0.1c-0.3,0.5-0.8,0.7-1.4,0.7c-0.7,0.1-1.4-0.5-1.5-1.2  C66.4,13.5,66.4,13.5,66.4,13.4z M69.3,13v-0.4l-1.1,0.1c-0.6,0-0.9,0.3-0.9,0.6c0,0.4,0.4,0.6,0.8,0.6  C68.7,14.1,69.2,13.7,69.3,13C69.3,13.1,69.3,13.1,69.3,13z"
						/>
						<path
							class="st0"
							d="M71.3,12.4c0-1.4,0.7-2.3,1.9-2.3c0.6,0,1.1,0.3,1.4,0.8h0.1V8.4h0.9v6.3h-0.9V14h-0.1  c-0.3,0.5-0.8,0.8-1.4,0.8C72.1,14.8,71.3,13.9,71.3,12.4z M72.3,12.4c0,1,0.5,1.5,1.2,1.5c0.7,0,1.2-0.6,1.2-1.5  c0-0.9-0.5-1.5-1.2-1.5C72.7,10.9,72.3,11.5,72.3,12.4L72.3,12.4z"
						/>
						<path
							class="st0"
							d="M79.2,12.4c-0.1-1.2,0.7-2.2,1.9-2.3c1.2-0.1,2.2,0.7,2.3,1.9c0,0.1,0,0.3,0,0.4c0.1,1.2-0.7,2.2-1.9,2.3  c-1.2,0.1-2.2-0.7-2.3-1.9C79.2,12.7,79.2,12.6,79.2,12.4z M82.6,12.4c0-1-0.4-1.5-1.2-1.5c-0.8,0-1.2,0.6-1.2,1.5  c0,1,0.4,1.6,1.2,1.6C82.1,14,82.6,13.4,82.6,12.4z"
						/>
						<path
							class="st0"
							d="M84.7,10.2h0.9v0.7h0.1c0.2-0.5,0.8-0.8,1.3-0.8c0.8-0.1,1.5,0.5,1.6,1.4c0,0.1,0,0.2,0,0.3v2.9h-0.9V12  c0-0.7-0.3-1.1-1-1.1c-0.6,0-1.1,0.4-1.1,1c0,0.1,0,0.1,0,0.2v2.6h-0.9V10.2z"
						/>
						<path
							class="st0"
							d="M93.5,9.1v1.1h1V11h-1v2.3c0,0.5,0.2,0.7,0.6,0.7c0.1,0,0.2,0,0.3,0v0.7c-0.2,0-0.3,0-0.5,0  c-1,0-1.4-0.3-1.4-1.2V11h-0.7v-0.7h0.7V9.1H93.5z"
						/>
						<path
							class="st0"
							d="M95.7,8.4h0.9v2.5h0.1c0.2-0.5,0.8-0.9,1.4-0.8c0.8,0,1.5,0.6,1.6,1.4c0,0.1,0,0.2,0,0.3v2.9h-0.9V12  c0-0.7-0.3-1.1-1-1.1c-0.6,0-1.1,0.4-1.1,1c0,0.1,0,0.1,0,0.2v2.6h-0.9L95.7,8.4z"
						/>
						<path
							class="st0"
							d="M104.8,13.5c-0.2,0.8-1.1,1.4-2,1.3c-1.1,0-2.1-0.9-2.1-2c0-0.1,0-0.2,0-0.3c-0.2-1.1,0.6-2.2,1.8-2.3  c0.1,0,0.2,0,0.3,0c1.3,0,2,0.9,2,2.3v0.3h-3.2v0c-0.1,0.7,0.4,1.2,1.1,1.3c0,0,0.1,0,0.1,0c0.4,0.1,0.9-0.2,1.1-0.5L104.8,13.5z   M101.6,12h2.3c0-0.6-0.4-1.1-1-1.2c0,0-0.1,0-0.1,0C102.2,10.9,101.6,11.4,101.6,12C101.6,12,101.6,12,101.6,12L101.6,12z"
						/>
					</g>
				</g>
			</g>
		</svg>
	</div>
</template>
<style type="text/css">
.st0 {
	fill: #ffffff;
}
</style>
