<template>
	<div class="app-button">
		<svg
			version="1.1"
			id="artwork"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 135 40"
			style="enable-background: new 0 0 135 40"
			xml:space="preserve"
		>
			<g>
				<path
					id="path35"
					class="st0"
					d="M106.9,30h1.9V17.5h-1.9V30z M123.7,22l-2.1,5.4h-0.1l-2.2-5.4h-2l3.3,7.6l-1.9,4.2h1.9l5.1-11.8 L123.7,22z M113.2,28.6c-0.6,0-1.5-0.3-1.5-1.1c0-1,1.1-1.3,2-1.3c0.8,0,1.2,0.2,1.7,0.4C115.2,27.8,114.2,28.6,113.2,28.6 L113.2,28.6z M113.4,21.7c-1.4,0-2.8,0.6-3.3,1.9l1.7,0.7c0.4-0.7,1-0.9,1.7-0.9c1,0,1.9,0.6,2,1.6v0.1c-0.3-0.2-1.1-0.5-1.9-0.5 c-1.8,0-3.6,1-3.6,2.8c0,1.7,1.5,2.8,3.1,2.8c1.3,0,1.9-0.6,2.4-1.2h0.1v1h1.8v-4.8C117.2,23,115.5,21.7,113.4,21.7L113.4,21.7z  M101.9,23.5h-2.7v-4.3h2.7c1.4,0,2.2,1.2,2.2,2.1C104,22.3,103.2,23.5,101.9,23.5z M101.8,17.5h-4.5V30h1.9v-4.7h2.6 c2.1,0,4.1-1.5,4.1-3.9C105.9,19,103.9,17.5,101.8,17.5L101.8,17.5z M77.4,28.6c-1.3,0-2.4-1.1-2.4-2.6c0-1.5,1.1-2.6,2.4-2.6 c1.3,0,2.3,1.1,2.3,2.6C79.7,27.5,78.7,28.6,77.4,28.6z M79.6,22.7L79.6,22.7c-0.5-0.5-1.3-1-2.3-1c-2.1,0-4.1,1.9-4.1,4.3 c0,2.4,1.9,4.2,4.1,4.2c1,0,1.8-0.5,2.2-1h0.1v0.6c0,1.6-0.9,2.5-2.3,2.5c-1.1,0-1.9-0.8-2.1-1.5l-1.6,0.7c0.5,1.1,1.7,2.5,3.8,2.5 c2.2,0,4-1.3,4-4.4V22h-1.8L79.6,22.7z M82.6,30h1.9V17.5h-1.9V30z M87.3,25.9c0-1.6,1.3-2.5,2.2-2.5c0.7,0,1.4,0.4,1.6,0.9 L87.3,25.9z M93.1,24.5c-0.4-0.9-1.4-2.7-3.6-2.7c-2.2,0-4,1.7-4,4.3c0,2.4,1.8,4.3,4.2,4.3c1.9,0,3.1-1.2,3.5-1.9l-1.5-1 c-0.5,0.7-1.1,1.2-2.1,1.2c-0.9,0-1.6-0.4-2.1-1.3l5.7-2.4L93.1,24.5z M47.7,23.1v1.8h4.3c-0.1,1-0.5,1.8-1,2.3 c-0.6,0.6-1.6,1.3-3.3,1.3c-2.7,0-4.7-2.1-4.7-4.8s2.1-4.8,4.7-4.8c1.4,0,2.5,0.6,3.3,1.3l1.3-1.3c-1.1-1-2.5-1.8-4.5-1.8 c-3.6,0-6.7,3-6.7,6.6c0,3.6,3.1,6.6,6.7,6.6c2,0,3.4-0.6,4.6-1.9c1.2-1.2,1.6-2.9,1.6-4.2c0-0.4,0-0.8-0.1-1.1L47.7,23.1z  M58.8,28.6c-1.3,0-2.4-1.1-2.4-2.6c0-1.5,1.1-2.6,2.4-2.6c1.3,0,2.4,1,2.4,2.6C61.2,27.5,60.1,28.6,58.8,28.6z M58.8,21.8 c-2.4,0-4.3,1.8-4.3,4.3c0,2.4,1.9,4.3,4.3,4.3c2.4,0,4.3-1.8,4.3-4.3C63.1,23.5,61.2,21.8,58.8,21.8z M68.1,28.6 c-1.3,0-2.4-1.1-2.4-2.6c0-1.5,1.1-2.6,2.4-2.6c1.3,0,2.4,1,2.4,2.6C70.5,27.5,69.4,28.6,68.1,28.6z M68.1,21.8 c-2.4,0-4.3,1.8-4.3,4.3c0,2.4,1.9,4.3,4.3,4.3c2.4,0,4.3-1.8,4.3-4.3C72.4,23.5,70.5,21.8,68.1,21.8"
				/>
				<path
					id="path37"
					class="st1"
					d="M20.7,19.4L10.1,30.7c0,0,0,0,0,0c0.3,1.2,1.4,2.1,2.8,2.1c0.5,0,1-0.1,1.5-0.4l0,0l12-6.9 L20.7,19.4"
				/>
				<path
					id="path39"
					class="st2"
					d="M31.5,17.5L31.5,17.5l-5.2-3l-5.8,5.2l5.8,5.8l5.1-3c0.9-0.5,1.5-1.4,1.5-2.5 C33,18.9,32.4,18,31.5,17.5"
				/>
				<path id="path41" class="st3" d="M10.1,9.3C10,9.5,10,9.8,10,10v20c0,0.3,0,0.5,0.1,0.7l11-11L10.1,9.3" />
				<path
					id="path43"
					class="st4"
					d="M20.8,20l5.5-5.5l-12-6.9c-0.4-0.3-0.9-0.4-1.5-0.4c-1.3,0-2.5,0.9-2.8,2.1c0,0,0,0,0,0L20.8,20"
				/>
				<path
					id="path33"
					class="st5"
					d="M47.4,9.8h-2.9v0.7h2.2c-0.1,0.6-0.3,1.1-0.7,1.4c-0.4,0.3-0.9,0.5-1.5,0.5 c-0.7,0-1.2-0.2-1.7-0.7c-0.4-0.5-0.7-1-0.7-1.7c0-0.7,0.2-1.3,0.7-1.7c0.5-0.5,1-0.7,1.7-0.7c0.3,0,0.7,0.1,0.9,0.2 c0.3,0.1,0.5,0.3,0.7,0.5l0.6-0.6c-0.3-0.3-0.6-0.5-1-0.7c-0.4-0.2-0.8-0.2-1.3-0.2c-0.9,0-1.6,0.3-2.2,0.9 c-0.6,0.6-0.9,1.4-0.9,2.2s0.3,1.6,0.9,2.2c0.6,0.6,1.3,0.9,2.2,0.9c0.9,0,1.6-0.3,2.2-0.9c0.5-0.5,0.7-1.2,0.7-2 C47.4,10.1,47.4,9.9,47.4,9.8L47.4,9.8z M48.5,7v6H52v-0.7h-2.7v-1.9h2.5V9.6h-2.5V7.7H52V7L48.5,7z M57,7.7V7h-4.1v0.7h1.7V13h0.8 V7.7L57,7.7z M60.7,7h-0.8v6h0.8V7z M65.8,7.7V7h-4.1v0.7h1.7V13h0.8V7.7L65.8,7.7z M73.6,7.8c-0.6-0.6-1.3-0.9-2.2-0.9 c-0.9,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.3-0.9,2.2s0.3,1.6,0.9,2.2c0.6,0.6,1.3,0.9,2.2,0.9c0.9,0,1.6-0.3,2.2-0.9 c0.6-0.6,0.9-1.3,0.9-2.2C74.5,9.1,74.2,8.4,73.6,7.8z M69.8,8.3c0.4-0.5,1-0.7,1.6-0.7c0.7,0,1.2,0.2,1.6,0.7 c0.4,0.4,0.7,1,0.7,1.7c0,0.7-0.2,1.3-0.7,1.7c-0.4,0.5-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7c-0.4-0.5-0.7-1-0.7-1.7 C69.1,9.3,69.3,8.7,69.8,8.3z M76.3,9.3l0-1.2h0l3.1,4.9h0.8V7h-0.8v3.5l0,1.2h0L76.5,7h-0.9v6h0.8V9.3z"
				/>
			</g>
		</svg>
	</div>
</template>

<style type="text/css">
.st0 {
	fill: #ffffff;
}
.st1 {
	fill: #ea4335;
}
.st2 {
	fill: #fbbc04;
}
.st3 {
	fill: #4285f4;
}
.st4 {
	fill: #34a853;
}
.st5 {
	fill: #ffffff;
	stroke: #ffffff;
	stroke-width: 0.2667;
	stroke-miterlimit: 10;
}
</style>
