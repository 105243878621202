<template>
	<div class="circle-meter">
		<svg
			:viewBox="`0 0 ${dimensionsRatio.width * UNITS} ${dimensionsRatio.height * UNITS}`"
			:height="size || '100%'"
			:width="size || '100%'"
		>
			<g
				:transform="`rotate(${rotate},${UNITS / 2},${UNITS / 2}) ${
					reverse ? 'scale(1,-1) translate(0, -' + UNITS + ')' : ''
				}`"
			>
				<!--<circle 
			:cx="UNITS/2" 
			:cy="UNITS/2" 
			:r="getRadius()" 
			:stroke="stroke" 
			:stroke-width="getStrokeWidth(strokeWidth)" 
			fill="none"
			:stroke-dasharray="getLengths()"
		/>-->

				<path
					ref="path"
					:d="describeArc(UNITS / 2, UNITS / 2, getRadius(), this.startDegrees, 360 - this.startDegrees)"
					fill="none"
					:stroke="stroke"
					:stroke-width="getStrokeWidth(activeWidth)"
					:stroke-dasharray="getLengths()"
				/>
				<path
					:d="describeArc(UNITS / 2, UNITS / 2, getRadius(), 76, activeEnd())"
					fill="none"
					:stroke="activeStroke"
					:stroke-width="getStrokeWidth(activeWidth)"
					:stroke-dasharray="getLengths()"
				/>
			</g>
		</svg>
		<div class="circle-meter__value" :style="{ top: 50 / dimensionsRatio.height + '%' }">
			<span class="value__integer">{{ splitValue.integer }}</span>
			<span class="value__decimal">.{{ splitValue.decimal }}</span>
		</div>
	</div>
</template>

<script>
export default {
	beforeCreate() {
		// Arbitrary dimensions of SVG to set up user-space units
		this.UNITS = 200
		this.startDegrees = 76
	},
	data() {
		return {
			dimensionsRatio: {
				height: 1,
				width: 1
			}
		}
	},
	props: {
		size: {
			type: String,
			default: ''
		},
		dashCount: {
			type: Number,
			default: 90
		},
		rangeMin: {
			type: Number,
			default: 0
		},
		rangeMax: {
			type: Number,
			default: 100
		},
		currentValue: {
			type: [Number, String],
			default: 50
		},
		strokeWidth: {
			type: Number,
			default: 30
		},
		activeWidth: {
			type: Number,
			default: 30
		},
		stroke: {
			type: String,
			default: 'grey'
		},
		activeStroke: {
			type: String,
			default: 'blue'
		},
		dashSpacing: {
			type: Number,
			default: 1 / 2
		},
		rotate: {
			type: Number,
			default: 90
		},
		reverse: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		activeCount() {
			//when not full circle
			const actualDashCount = ((360 - this.startDegrees * 2) / 360) * 90

			const activeFactor = (this.currentValue - this.rangeMin) / (this.rangeMax - this.rangeMin)

			//keep within bounds
			let count
			count = Math.max(Math.round(activeFactor * actualDashCount), 0)
			count = Math.min(count, actualDashCount)
			return count
		},
		splitValue() {
			const n = this.currentValue
			const regex = /(-?\d*)[.,]?(\d*)/
			let m = regex.exec(n.toString())

			return {
				integer: m ? parseInt(m[1]) : 0,
				decimal: m && parseInt(m[2]) ? parseInt(m[2]) : 0
			}
		}
	},
	mounted() {
		this.getRatio()
	},
	updated() {
		//this.getRatio()
	},
	methods: {
		// Calculate ratio of component, for when the meter is not a full circle
		getRatio() {
			let w, h
			if (this.$refs.path) {
				w = this.$refs.path.getBoundingClientRect().width
				h = this.$refs.path.getBoundingClientRect().height
			}

			const ratio = h && w ? h / w : 1

			this.dimensionsRatio = {
				width: 1,
				height: ratio
			}
		},
		// Stroke is provided as a percentage of the radius, translate into user space units
		getStrokeWidth(stroke) {
			return (stroke * this.UNITS) / 200
		},
		// Determine the 'end' angle of the path for the active dashes in degrees.
		activeEnd() {
			if (this.activeCount == 0) {
				return this.startDegrees
			}
			return (
				360 * (this.activeCount * this.dashPerc() + (this.activeCount - 1) * this.spacePerc()) +
				this.startDegrees
			)
		},
		// An array of the length of the dash & the length of the space between dashes
		getLengths() {
			return [2 * Math.PI * this.getRadius() * this.dashPerc(), 2 * Math.PI * this.getRadius() * this.spacePerc()]
		},
		// The space beween dashes as a percentage of the total length
		spacePerc() {
			return this.dashSpacing / this.dashCount
		},
		// The length of a dash as a percentage of the total length
		dashPerc() {
			return (1 - this.dashSpacing) / this.dashCount
		},
		// Radius of the circle arc
		getRadius() {
			return (
				(this.UNITS - Math.max(this.getStrokeWidth(this.strokeWidth), this.getStrokeWidth(this.activeWidth))) /
				2
			)
		},
		// SVG path definition requires points in cartesian space
		polarToCartesian(cx, cy, radius, degrees) {
			const radians = (degrees * Math.PI) / 180.0
			return {
				x: cx + radius * Math.cos(radians),
				y: cy + radius * Math.sin(radians)
			}
		},
		// Path definition for circular arc
		describeArc(cx, cy, radius, startDegrees, endDegrees) {
			const start = this.polarToCartesian(cx, cy, radius, startDegrees)
			const end = this.polarToCartesian(cx, cy, radius, endDegrees)
			let largeArc = Math.abs(endDegrees - startDegrees) < 180 ? 0 : 1
			let sweep = endDegrees < startDegrees ? 0 : 1
			return `M${start.x} ${start.y} A${radius} ${radius} 0 ${largeArc} ${sweep} ${end.x} ${end.y}`
		}
	}
}
</script>
