<template>
	<canvas ref="chart" width="400" height="350"></canvas>
	<template v-if="showRange">
		<p>{{ $t('message.front_temperature_logs_range') }}</p>
		<Range
			:max="dataLabels.length"
			:startValue="dataPointsShown.start"
			:endValue="dataPointsShown.end"
			@updated="updateRange"
		/>
	</template>
</template>

<script>
import Chart from 'chart.js/auto'
import Range from '@/components/bits/Range'
import annotationPlugin from 'chartjs-plugin-annotation'
import { defineComponent, createApp } from 'vue'
import shockPointBase from '@/assets/shockPoint'
import tiltPointBase from '@/assets/tiltPoint'

Chart.register(annotationPlugin)
let chart

export default {
	name: 'ShockSenseChart',
	components: {
		Range
	},
	data() {
		return {
			src: null,
			dataPointsShown: {
				start: 0,
				end: this.dataLabels.length
			}
		}
	},
	props: {
		fontColor: {
			type: String,
			default: 'grey'
		},
		gridColor: {
			type: String,
			default: 'grey'
		},
		primaryColor: {
			type: String,
			default: 'black'
		},
		secondaryColor: {
			type: String,
			default: 'black'
		},
		darkColor: {
			type: String,
			default: 'black'
		},
		dataPoints: {
			default: () => [120, 19, 3, 5, 2, 3]
		},
		dataLabels: {
			default: () => ['1', '2', '3', '4', '5', '6']
		},
		showRange: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		shockThreshold() {
			return 7
		},
		tiltThreshold() {
			return 15
		},
		selectedTiltDataPoints() {
			return this.selectedDataLabels.map((dataLabel, index) => {
				const interval = this.dataPointsShown.start + index
				return this.dataPoints.tilt.includes(interval) ? this.tiltThreshold : null
			})
		},
		selectedShockDataPoints() {
			return this.selectedDataLabels.map((dataLabel, index) => {
				const interval = this.dataPointsShown.start + index
				return this.dataPoints.shock.includes(interval) ? this.shockThreshold : null
			})
		},
		selectedDataLabels() {
			return this.dataLabels.slice(this.dataPointsShown.start, this.dataPointsShown.end)
		},
		shockPoint() {
			const vm = this
			const shockSenseIcon = defineComponent({
				extends: shockPointBase,
				data() {
					return {
						fill: vm.primaryColor,
						iconFill: vm.darkColor
					}
				}
			})
			const div = document.createElement('div')
			createApp(shockSenseIcon).mount(div)

			const shockImage = new Image()
			const shockImageUrl = encodeURIComponent(div.children[0].outerHTML)
			shockImage.src = `data:image/svg+xml,${shockImageUrl}`
			return shockImage
		},
		tiltPoint() {
			const vm = this
			const shockSenseIcon = defineComponent({
				extends: tiltPointBase,
				data() {
					return {
						fill: vm.secondaryColor,
						iconFill: vm.darkColor
					}
				}
			})
			const div = document.createElement('div')
			createApp(shockSenseIcon).mount(div)

			const shockImage = new Image()
			const shockImageUrl = encodeURIComponent(div.children[0].outerHTML)
			shockImage.src = `data:image/svg+xml,${shockImageUrl}`
			return shockImage
		}
	},
	watch: {
		selectedDataLabels(newLabels) {
			chart.data.labels = newLabels
			chart.update()
		},
		selectedShockDataPoints(newData) {
			chart.data.datasets[0].data = newData
			chart.update()
		},
		selectedTiltDataPoints(newData) {
			chart.data.datasets[1].data = newData
			chart.update()
		}
	},
	mounted() {
		const tiltThreshold = {
			drawTime: 'beforeDatasetsDraw',
			type: 'line',
			scaleID: 'tiltEvents',
			value: this.tiltThreshold,
			borderColor: this.fontColor,
			borderWidth: 0.5,
			borderDash: [6, 3],
			label: {
				backgroundColor: 'black',
				content: 'Threshold',
				enabled: false
			}
		}

		const shockThreshold = {
			drawTime: 'beforeDatasetsDraw',
			type: 'line',
			scaleID: 'shockEvents',
			value: this.shockThreshold,
			borderColor: this.fontColor,
			borderWidth: 0.5,
			borderDash: [6, 3],
			label: {
				backgroundColor: 'black',
				content: 'Threshold',
				enabled: false
			}
		}

		const data = {
			labels: this.selectedDataLabels,
			datasets: [
				{
					label: this.$t('message.front_shock_chart_label'),
					data: this.selectedShockDataPoints, //[null, 50, 50, null, null, null, 50, null],
					borderColor: this.primaryColor,
					backgroundColor: this.primaryColor,
					radius: 20,
					yAxisID: 'shockEvents',
					pointHoverRadius: 24,
					hitRadius: 24,
					showLine: false,
					pointStyle: [this.shockPoint]
				},
				{
					label: this.$t('message.front_tilt_chart_label'),
					data: this.selectedTiltDataPoints,
					//data: [null, 25, 25, 25, null, null, 25, null],
					borderColor: this.secondaryColor,
					backgroundColor: this.secondaryColor,
					radius: 20,
					pointHoverRadius: 24,
					yAxisID: 'tiltEvents',
					hitRadius: 24,
					showLine: false,
					pointStyle: [this.tiltPoint]
				}
			]
		}

		const options = {
			responsive: true,
			animation: {
				duration: 0
			},
			plugins: {
				title: {
					display: false
				},
				legend: {
					display: true
				},
				annotation: {
					annotations: {
						tiltThreshold,
						shockThreshold
					}
				},
				tooltip: {
					backgroundColor: this.colorLightTertiary,
					//boxPadding: 5,
					displayColors: false,
					titleColor: this.colorDark,
					titleFont: {
						size: 14
					},
					titleFontStyle: 'bold',
					titleMarginBottom: 2,
					bodyColor: this.colorDark,
					bodyFont: {
						size: 14
					},
					bodySpacing: 0,
					footerSpacing: 0,
					footerMarginTop: 0,
					padding: {
						top: 8,
						left: 15,
						bottom: 6,
						right: 15
					},
					cornerRadius: 10,
					caretSize: 7,
					callbacks: {
						label: (context) => {
							return context.dataset.yAxisID === 'shockEvents'
								? this.$t('message.front_shock_threshold_exceeded')
								: this.$t('message.front_tilt_threshold_exceeded')
						}
					}
				}
			},
			scales: {
				y: {
					//empty y axis to prevent annotation plugin to warn for missing y axis
					display: false
				},
				yRightBorder: {
					position: 'right',
					stack: 'shock',
					grid: {
						borderColor: this.gridColor,
						borderWidth: 1,
						drawTicks: false,
						display: false
					},
					ticks: {
						display: false
					}
				},
				xTopBorder: {
					position: 'top',
					grid: {
						borderColor: this.gridColor,
						borderWidth: 1,
						drawTicks: false,
						display: false
					},
					ticks: {
						display: false
					}
				},
				x: {
					grid: {
						drawTicks: false,
						color: this.gridColor,
						borderColor: this.gridColor
					},
					ticks: {
						padding: 10
					}
				},
				tiltEvents: {
					min: 0,
					max: 60,
					type: 'linear',
					position: 'left',
					stack: 'shock',
					stackWeight: 2,
					grid: {
						color: this.gridColor,
						borderColor: this.secondaryColor,
						borderWidth: 3,
						drawTicks: false
					},
					ticks: {
						padding: 10,
						callback: function (value, index, values) {
							return `${value}s`
						}
					}
				},
				shockEvents: {
					min: 0,
					max: 25,
					type: 'linear',
					offset: true,
					position: 'left',
					stack: 'shock',
					stackWeight: 2,
					grid: {
						color: this.gridColor,
						borderColor: this.primaryColor,
						borderWidth: 3,
						drawTicks: false
					},
					ticks: {
						padding: 10,
						callback: function (value, index, values) {
							return `${value}g`
						}
					}
				}
			}
		}

		Chart.defaults.font.family = 'Jakarta Sans'
		Chart.defaults.color = this.fontColor

		const ctx = this.$refs.chart
		chart = new Chart(ctx, {
			type: 'line',
			data,
			options
		})

		//update to show events
		this.$nextTick(() => chart.update())
	},
	methods: {
		updateRange(range) {
			this.dataPointsShown.start = range.start
			this.dataPointsShown.end = range.end
		}
	}
}
</script>
