<template>
	<svg
		width="40px"
		height="40px"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 40 40"
		style="enable-background: new 0 0 40 40"
		xml:space="preserve"
		preserveAspectRatio="xMidYMid"
	>
		<circle :fill="fill" cx="20" cy="20" r="20" />
		<path
			d="M32.5,19.16c0-1.33-1.19-2.41-3.53-3.21c-0.54-0.18-1.14-0.35-1.79-0.5c-0.36-0.08-0.74-0.15-1.12-0.21
	c-0.75-4.25-2.53-7.74-5.22-7.74c-3.52,0-5.5,5.95-5.63,11.83c-0.01,0.55,0.43,1.01,0.98,1.02c0.01,0,0.02,0,0.02,0
	c0.54,0,0.99-0.43,1-0.98c0.13-6.12,2.21-9.88,3.63-9.88c1.07,0,2.43,1.99,3.15,5.44c-0.79-0.08-1.62-0.14-2.46-0.17l0.86-0.84
	c0.4-0.39,0.4-1.02,0.02-1.41c-0.39-0.4-1.02-0.4-1.41-0.02l-2.55,2.49c-0.19,0.19-0.3,0.44-0.3,0.7c0,0.26,0.1,0.52,0.28,0.71
	l2.49,2.55c0.2,0.2,0.46,0.3,0.72,0.3c0.25,0,0.5-0.09,0.7-0.28c0.4-0.38,0.4-1.02,0.02-1.41l-0.77-0.79
	c0.95,0.04,1.86,0.12,2.73,0.23c0.1,0.93,0.17,1.93,0.17,3c0,0.45-0.01,0.89-0.03,1.32c-1.29,0.17-2.77,0.27-4.44,0.27
	c-6.82,0-10.5-1.72-10.5-2.43c0-0.16,0.72-1.13,4.17-1.84c0.54-0.11,0.89-0.64,0.78-1.18s-0.64-0.89-1.18-0.78
	C10.64,15.9,7.5,17,7.5,19.16c0,3.04,6.48,4.43,12.5,4.43c1.43,0,2.88-0.08,4.27-0.24c-0.56,4.5-2.2,7.15-3.43,7.15
	c-0.69,0-1.62-0.9-2.38-2.83l0.91,0.5c0.49,0.26,1.09,0.08,1.36-0.4c0.26-0.49,0.08-1.09-0.4-1.36l-3.13-1.7
	c-0.49-0.26-1.09-0.08-1.36,0.4l-1.7,3.13c-0.26,0.49-0.08,1.09,0.4,1.36c0.15,0.08,0.32,0.12,0.48,0.12c0.35,0,0.7-0.19,0.88-0.52
	l0.59-1.09c1.04,2.81,2.58,4.4,4.36,4.4c3.05,0,4.92-4.46,5.46-9.45C29.82,22.39,32.5,21.12,32.5,19.16z M26.45,20.98
	c0.01-0.33,0.02-0.65,0.02-0.98c0-0.89-0.05-1.78-0.13-2.67c0.13,0.03,0.27,0.05,0.4,0.08c0.58,0.13,1.11,0.28,1.59,0.44
	c1.79,0.61,2.17,1.2,2.17,1.31C30.5,19.6,29.11,20.41,26.45,20.98z"
		/>
	</svg>
</template>

<script>
export default {
	data() {
		return {
			fill: 'grey',
			iconFill: 'black'
		}
	}
}
</script>
